<template>
<div id="internal">

  <div class="hidden-sm-and-down">
    <PageHeader/>
  </div>
  <div class="hidden-md-and-up">
    <m-top/>
  </div>

  <div class="hidden-xs-only">
    <el-row type="flex" justify="center">
      <el-col :md="20" :lg="18" :xl="15">
        <div style="width: 100%;max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">プラセンタ</div>

          </div>

          

          <div class="separator">

              <div class="img_banner">
                <div style="position: absolute;width: 100%;">
                  
                </div>
                <img style="width: 100%;" src="../../assets/departments/placenta/beauty_01.png">
                
              </div>
                
              <div class="bg_black">
                <div class="side-text">
                <div class="slogan_big_l" style="color:#CBB8B2;">プラセンタとは？</div>
                </div>
                <div class="content1">
                  <div class="side-text">
                    
                    <div class="side-box-img">
                      <div style="width: 60%;">
                        <div class="side-box">
                          <div class="side-line-highlight"></div>

                          <div class="title1">プラセンタは胎盤から抽出したエキスのこと</div>

                        </div>
                        <div class="content_text" >

                          <div class="text1">プラセンタとは母親のお腹の中で赤ちゃんを守り、育てる役割をもった胎盤から抽出されるエキスのことです。</div>
                          <div class="text1">古くは紀元前から利用されてきた長い歴史があり、エジプトの女王 クレオパトラのマリーアントワネット王妃が美容のために利用してきたといわれています。</div>
                          <div class="text1">プラセンタには胎児の発育を支える【タンパク質、脂質、糖質、ビタミン、ミネラル】 の5大栄養素をはじめ、核酸、ヒアルロン酸などのムコ多糖類、酵素、など美容に大切な様々な栄養素が含まれています。</div>
                          
                        </div>
                      </div>

                      <div class="side-img" style="width: 40%; margin-right: 0px;">
                          <img src="../../assets/departments/placenta/beauty_02.jpg" class="fit_img">
                      </div>

                      </div>
                  </div>



                  
                </div>
              </div>

              <div class="side-box" style="height: 200px; overflow: hidden;">
                <div style="position:absolute;width: 50%;left: 25%;">
                  <div class=" slogan_large font_border">プラセンタの効能・効果</div>
                </div>
              </div>

              <div class="bg_blue">

                <div class="side-text">
                  <div class="side-box-img">
                    <div class="side-img" style="width: 40%; margin-left: 0px">
                      <img src="../../assets/departments/placenta/beauty_03.png" class="fit_img">
                    </div>
                    <div style="width: 60%;">
                      <div class="side-text">
                        <div class="text1">
                          １．肝機能の改善<br>
                          ２．美肌効果<br>
                          ３．疲労回復・体力増強<br>
                          ４．更年期障害の緩和<br>
                          ５．免疫力向上<br>
                          ６．抗炎症作用<br>
                          ７．自律神経の調整<br>
                          ８．ホルモンバランスの調整
                        </div>
                        <div class="text1">厚生労働省の認定した効能は肝機能障害、更年期障害、乳汁分泌不全ですが、これ以外に細胞の新陳代謝を改善することなどからアレルギー疾患（アトピー性皮膚炎、アレルギー性鼻炎など）、肩こり、肌荒れなどの美容目的、などに効果が期待できます。</div>
                        
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="bg_green">
                <div class="side-text">
                <div class="slogan_big_l" style="color:#CBB8B2;">治療</div>
                </div>
                <div class="content1">
                  <div class="side-text">
                    <div class="side-box-img">
                      <div style="width: 60%;">
                        <div class="text1">ドナースクリーニング ⇒ 受け入れ検査 ⇒ 高温（高圧蒸気）滅菌処理 ⇒ 製品試験 このように3回にわたる検査や滅菌工程により、梅毒、結核、淋病、B型肝炎、C型肝炎、エイズ（後天性免疫不全症候群）、成人性T細胞白血病、りんご病（パルボウィルス）などの陰陽清濁を確認しており、ウィルス・細菌による本製品の汚染防止対策を講じております。</div>
                        <div class="text1">ちなみに変異型クロイツフェルト・ヤコブ病（狂牛病）などの伝播のリスクを理論的には否定できませんが、本製品を含めヒトプラセンタを原料として製造される医薬品の投与により、感染症が伝播したとの報告は、現在までに国内外ともにありません。正常のプリオンが変異するには一定の時間が必要ですが、ヒトプラセンタは母親の体内にとどまるのが約10ヶ月と限られた非常に暫定的な組織であるため、理論的にはプリオンの感染を起こす可能性は少ないと思われます。</div>
                        <div class="text1">※なお、本注射を施行した場合、厚生労働省からの通達により献血ができなくなりますので、その旨ご了承下さい。</div>
                      </div>
                      <div class="side-img" style="width: 40%;margin-right: 0px;">
                        <img src="../../assets/departments/placenta/beauty_04.png" class="fit_img">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="content1" style="height: 15px;"></div>
                <div class="side-text">
                  <div class="slogan_big_l" style="color:#CBB8B2;">副作用</div>
                </div>
                <div class="content1">
                  <div class="side-text">
                    <div class="side-box-img">
                      <div style="width: 100%;">

                        <div class="text1">まれ（発現率3.0％未満）に、ヒトプラセンタの注射により、過敏症（発疹、発熱、掻痒感など）の症状が現れる場合がありますので、もし本製剤の投与を受けてからこのような症状が出現した場合、あるいは不安に思われた場合は、必ず担当医師にご相談下さい。 </div>
                        
                      </div>
                    </div>
                  </div>
                </div>


                
              </div>

              <table class="table_ryoukin">
                <tr class="ryoukin_name_top">
                  <td colspan="2"><div style="margin-bottom: 15px;font-size: 30px;letter-spacing: 5px;padding-top: 20px;font-weight: bold;">料金</div></td>
                </tr>
                <tr>
                  <td class="ryoukin_name" style="width: 50%;">メルスモン</td>
                  <td class="ryoukin_name" style="width: 50%;">ラエンネック</td>
                </tr>
                <tr>
                  <td colspan="2" class="ryoukin_name">１アンプル：1,100円（税込）</td>
                </tr>
              </table>

          </div>
        </div>
      </el-col>
    </el-row>
    <PageFooter/>
  </div>

  <div class="hidden-sm-and-up">

    <div style="width: 100%;max-width: 1284px; margin: 0 auto;">
          <div class="side-box" style="margin-bottom: 30px;">
            <div class="title">プラセンタ</div>

          </div>

          

          <div class="separator">

              <div class="img_banner">
                <img style="width: 100%;" src="../../assets/departments/placenta/beauty_01.png">
                
              </div>
                
              <div class="bg_black">
                <div class="m_side-text">
                <div class="slogan_big_l" style="color:#CBB8B2;">プラセンタとは？</div>
                </div>
                <div class="content1">
                  <div class="m_side-text">
                    
                    <div>
                      <div>
                        <div class="side-box">
                          <div class="side-line-highlight"></div>

                          <div class="title1">プラセンタは胎盤から抽出したエキスのこと</div>

                        </div>
                        <div class="content_text" >

                          <div class="text1">プラセンタとは母親のお腹の中で赤ちゃんを守り、育てる役割をもった胎盤から抽出されるエキスのことです。</div>
                          <div class="text1">古くは紀元前から利用されてきた長い歴史があり、エジプトの女王 クレオパトラのマリーアントワネット王妃が美容のために利用してきたといわれています。</div>
                          <div class="text1">プラセンタには胎児の発育を支える【タンパク質、脂質、糖質、ビタミン、ミネラル】 の5大栄養素をはじめ、核酸、ヒアルロン酸などのムコ多糖類、酵素、など美容に大切な様々な栄養素が含まれています。</div>
                          
                        </div>
                      </div>

                      <div class="side-img">
                          <img src="../../assets/departments/placenta/beauty_02.jpg" class="fit_img">
                      </div>

                      </div>
                  </div>



                  
                </div>
              </div>

              <div class="side-box" style="height: 150px; overflow: hidden;">
                <div style="position:absolute;width: 80%;left: 10%;">
                  <div class=" slogan_big_l font_border">プラセンタの効能・効果</div>
                </div>
              </div>

              <div class="bg_blue">

                <div class="m_side-text">
                  <div>
                    <div class="side-img">
                      <img src="../../assets/departments/placenta/beauty_03.png" class="fit_img">
                    </div>
                    <div>
                      <div class="m_side-text">
                        <div class="text1">
                          １．肝機能の改善<br>
                          ２．美肌効果<br>
                          ３．疲労回復・体力増強<br>
                          ４．更年期障害の緩和<br>
                          ５．免疫力向上<br>
                          ６．抗炎症作用<br>
                          ７．自律神経の調整<br>
                          ８．ホルモンバランスの調整
                        </div>
                        <div class="text1">厚生労働省の認定した効能は肝機能障害、更年期障害、乳汁分泌不全ですが、これ以外に細胞の新陳代謝を改善することなどからアレルギー疾患（アトピー性皮膚炎、アレルギー性鼻炎など）、肩こり、肌荒れなどの美容目的、などに効果が期待できます。</div>
                        
                      </div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="bg_green">
                <div class="m_side-text">
                <div class="slogan_big_l" style="color:#CBB8B2;">治療</div>
                </div>
                <div class="content1">
                  <div class="m_side-text">
                    <div>
                      <div>
                        <div class="text1">ドナースクリーニング ⇒ 受け入れ検査 ⇒ 高温（高圧蒸気）滅菌処理 ⇒ 製品試験 このように3回にわたる検査や滅菌工程により、梅毒、結核、淋病、B型肝炎、C型肝炎、エイズ（後天性免疫不全症候群）、成人性T細胞白血病、りんご病（パルボウィルス）などの陰陽清濁を確認しており、ウィルス・細菌による本製品の汚染防止対策を講じております。</div>
                        <div class="text1">ちなみに変異型クロイツフェルト・ヤコブ病（狂牛病）などの伝播のリスクを理論的には否定できませんが、本製品を含めヒトプラセンタを原料として製造される医薬品の投与により、感染症が伝播したとの報告は、現在までに国内外ともにありません。正常のプリオンが変異するには一定の時間が必要ですが、ヒトプラセンタは母親の体内にとどまるのが約10ヶ月と限られた非常に暫定的な組織であるため、理論的にはプリオンの感染を起こす可能性は少ないと思われます。</div>
                        <div class="text1">※なお、本注射を施行した場合、厚生労働省からの通達により献血ができなくなりますので、その旨ご了承下さい。</div>
                      </div>
                      <div class="side-img">
                        <img src="../../assets/departments/placenta/beauty_04.png" class="fit_img">
                      </div>
                    </div>
                  </div>
                </div>

                <div class="content1" style="height: 15px;"></div>
                <div class="m_side-text">
                  <div class="slogan_big_l" style="color:#CBB8B2;">副作用</div>
                </div>
                <div class="content1">
                  <div class="m_side-text">
                    <div class="side-box-img">
                      <div style="width: 100%;">

                        <div class="text1">まれ（発現率3.0％未満）に、ヒトプラセンタの注射により、過敏症（発疹、発熱、掻痒感など）の症状が現れる場合がありますので、もし本製剤の投与を受けてからこのような症状が出現した場合、あるいは不安に思われた場合は、必ず担当医師にご相談下さい。 </div>
                        
                      </div>
                    </div>
                  </div>
                </div>


                
              </div>
              <table class="table_ryoukin">
                <tr class="ryoukin_name_top">
                  <td colspan="2"><div style="margin-bottom: 15px;font-size: 30px;letter-spacing: 5px;padding-top: 20px;font-weight: bold;">料金</div></td>
                </tr>
                <tr>
                  <td class="ryoukin_name" style="width: 50%;">メルスモン</td>
                  <td class="ryoukin_name" style="width: 50%;">ラエンネック</td>
                </tr>
                <tr>
                  <td colspan="2" class="ryoukin_name">１アンプル：1,100円（税込）</td>
                </tr>
              </table>
          </div>
        </div>

    <m-departs/>
    <m-footer/>
  </div>
</div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import DepartmentAside from "@/components/DepartmentAside";
import MTop from "../../components/m/MTop";
import MFooter from "../../components/m/MFooter";
import MDeparts from "../../components/m/MDeparts";
import DietSidebar from "@/components/DietSidebar";
export default {
  name: "Placenta",
  components: {MDeparts, MFooter, MTop, DepartmentAside, PageFooter, PageHeader,DietSidebar}
}
</script>

<style scoped>
/* PC */
.hidden-sm-and-down .img {
  margin-top: 30px;
}
.detail1 {
  margin: 40px 20px;
}
.content1 {
      margin-top: 40px;
    }
    .content_text{
        margin-top: 18px;
    }
    .title1 {
      color: #CBB8B2;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .text1 {
      color: #ebebeb;
      line-height: 1.5;
      margin-bottom: 15px;
    }
.other {
  color: #535353;
  font-size: 14px;
  line-height: 1.5;
}

.seperate_line{
      height:5px;
      border-top-color: #CBB8B2;
      border-top-width: 2px;
      border-top-style: solid;
      margin: 0 auto;
      width: 100%;
    }

/* SP */
.m-main {
  background-color: #FFFFFF;
  border-top: 2px solid #CBB8B2;
  border-bottom: 2px solid #CBB8B2;
  padding: 2rem 0;
}
.m-title {
  color: #535353;
  font-size: 24px;
  text-align: center;
  margin: 2rem 0;
}
.m-detail {
  background-color: #FFFFFF;
  padding: 0 1rem;
}
.m-subtitle {
  color: #CBB8B2;
  font-size: 18px;
  padding: 2rem 0 1rem;
}
.m-content {
  line-height: 24px;
  color: #535353;
}

.center-title{
  color: #535353;
  font-size: 24px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.LINE_btn{
  background-color: #20ce88;
  border-radius: 5px; 
  color: #FFFFFF;
  width:300px;
  height:50px;
  position: absolute;
  right: 0px;
  margin-right: 22%;
}

.banner_slogan{

  text-align: center;
  color: #326686;
  text-shadow: 1px 2px 0px #e9d5ce6b,
    0px 2px 3px #326686c4
    ;
  width: 100%;
}

.slogan_small{
      font-size: 15px;
      margin-top: 5px;
    }
    
    .slogan_big{
      font-size: 18px;
      margin-top: 15px;
    }
    
    .slogan_big_l{
      font-size: 30px;
      margin-top: 15px;
    }
    
    .slogan_large{
      font-size: 50px;  
    }

.m_slogan_small{
  font-size: 5px;
  margin-top: 5px;
}

.m_slogan_big{
  font-size: 10px;
  margin-top: 5px;
}

.m_slogan_big_l{
  font-size: 12px;
  margin-top: 5px;
}

.m_slogan_large{
  font-size: 20px;  
}

.font_border{
  text-align: center;
  color: #326686;
  text-shadow: 
    0px 1px 4px #f5efe3,
    0px -1px 4px #f5efe3,
    1px 0px 4px #f5efe3,
    -1px 0px 4px #f5efe3;
  width: 100%;
}

.img_banner{
  position: relative;
  height: auto;
}

.table_ryoukin{
 
  background-color: #ffffff;
  color: #535353;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  border-collapse:collapse;
}

.table_ryoukin tr{
  height:60px;
  text-align: center;
}

.ryoukin_name_top{
  font-size: 24px;
  text-align: center;
}

.ryoukin_name{
  vertical-align: middle;
  border-color: #bec6cb;
  border-width: 1px;
  font-size: 22px;
  font-weight: bold;
  border-style: solid; 
}

.bg_black{
      background-color: #4d4f4f;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
      height:auto;
  
    }
    
    .bg_blue{
      background-color: #326686;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
      height: auto;
    }
    
    .bg_green{
      background-color: #5a7977;
      padding-top: 30px;
      padding-bottom: 30px;
      width: 100%;
      height: auto;
    }

.m_bg_black{
  background-color: #4d4f4f;
  width: 100%;
  height:fit-content;
  padding-bottom: 100px;
}

.m_bg_blue{
  background-color: #326686;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 50px;
}

.m_bg_green{
  background-color: #5a7977;
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 50px;
}

.ryoukin_hint{
  color: #F71B1B;
  font-size: 16px;
  text-align: right;
  padding-right: 50px;
}

.title {
      font-size: 30px;
      letter-spacing: 5px;
      padding-top: 20px;
      padding-left: 20px;
    }

.m_title {
  font-size: 20px;
  letter-spacing: 5px;
  padding-left: 20px;
}

.separator {
      color: #CBB8B2;
      width: 100%;
      margin: 0 auto;
      border-top: 4px solid;
      border-bottom: 4px solid;
    }

    .side-img{
    height: auto;
    width:auto;
    vertical-align:middle;
    margin-left: 30px;
    margin-right: 30px;
    }
  
    .side-text{
    height: fit-content;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: auto;
    }
    .m_side-text{
    height: fit-content;
    margin-left: 7%;
    margin-right: 7%;
    width: 86%;
    margin-bottom: auto;
    }

.side-text-line{
  height: fit-content;
}



.side-box{
      display: inline-flex;
      justify-content: space-between;
      align-items:center;
      vertical-align: top;
      height: auto;
    }
  
    .side-box-img{
      display: inline-flex;
      justify-content: space-between;
      align-items:top;
      vertical-align: top;
      height: auto;
    }
    .fit_img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .side-line-highlight{
      background-color: #CBB8B2;
      width:6px;
      height: 25px;
      margin-bottom: 10px;
      margin-right: 10px;
    }

</style>
